<template>
  <v-dialog
    v-model="dialog"
    max-width="300px"
    :persistent="load"
  >
    <v-card :disabled="load">
      <v-card-title>
        {{ p_nuevo ? 'Nuevo modelo' : 'Editar modelo' }}
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="dialog = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form ref="form">
          <v-row class="pt-8">
            <v-col cols="12" class="py-0">
              <v-text-field
                v-model="modelo.nombre"
                label="Nombre"
                :rules="[rules.required]"
                validate-on-blur
                autofocus
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="10" class="py-0">
              <v-autocomplete
                v-model="modelo.marca_codigo"
                label="Marca"
                item-text="nombre"
                item-value="codigo"
                :rules="[rules.required]"
                :items="marcas.filter(marca => marca.inhabilitada != 1)"
                validate-on-blur
                outlined
                dense
              ></v-autocomplete>
            </v-col>
            <v-col cols="2" class="pa-0">
              <v-btn
                color="success"
                title="Nueva marca"
                small
                icon
                @click="dialog_marca = true"
              >
                <v-icon>fas fa-plus</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="d-flex justify-end pb-4">
        <v-btn
          color="error"
          class="mr-2"
          :disabled="load"
          @click="dialog = false"
        >
          Cancelar
        </v-btn>
        <BtnConfirmar
          :loading="load"
          @action="guardar()"
        />
      </v-card-actions>
    </v-card>
    <Marca
      v-model="dialog_marca"
      :p_nueva="true"
      :p_marca="{ codigo: null, nombre: null }"
      @nueva="nueva_marca"
    />
  </v-dialog>
</template>

<script>
/**
 *  Modal para editar o crear una modelo
 * 
 *  Notas: este componente emite acciones (@nuevo, @editar) al padre caundo se crea o edita un modelo (en caso de estar definidas)
 *         todos los props son obligatorios
 */
import BtnConfirmar from '../util/BtnConfirmar'
import Marca from './Marca'

export default {
  data () {
    return {
      load: false,
      dialog_marca: false,
      modelo: {},
      rules: {
        required: value => !!value || 'Campo requerido',
      }
    }
  },
  props: {
    value: Boolean,
    p_nuevo: Boolean,  // para editar un modelo enviar en false, para un modelo nuevo en true
    p_modelo: Object,  // en caso de ser nuevo enviar el objeto con los atributros en null desde el padre
    /**
     *  Ej de p_modelo = {
                codigo: null,
                marca_codigo: null,
                nombre: null
              }
     */
    p_marcas: Array    // array de todas las marcas habilitadas (enviar sincronizado por si crea una nueva marca desde este componente)
  },
  computed: {
    dialog: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    marcas: {
      get () {
        return this.p_marcas
      },
      set (value) {
        this.$emit('update:p_marcas', value)
      }
    }
  },
  components: {
    BtnConfirmar,
    Marca
  },
  watch: {
    dialog (val) {
      if (val) {
        // crea una copia del prop p_modelo para editarlo
        this.modelo = JSON.parse(JSON.stringify(this.p_modelo))
      } else {
        this.$refs.form.resetValidation()
      }
    }
  },
  methods: {
    async guardar () {
      if (this.$refs.form.validate()) {
        this.load = true
        if (this.p_nuevo) {
          await this.$store.dispatch('modelos/nuevo', this.modelo)
            .then((res) => {
              this.dialog = false
              // emite la accion definifa en el padre
              this.$emit('nuevo', res.modelo)
              // muestra mensaje
              this.$store.dispatch('show_snackbar', {
                text: res.message,
                color: 'success',
              })
            })
            .catch(error => {
              this.$store.dispatch('show_snackbar', {
                text: error.message,
                color: 'error',
              })
            })
        } else {
          // solo edita si realmente cambio el nombre o la marca
          if (this.modelo.nombre != this.p_modelo.nombre || this.modelo.marca_codigo != this.p_modelo.marca_codigo) {
            await this.$store.dispatch('modelos/editar', this.modelo)
              .then((res) => {
                this.dialog = false
                // emite la accion definifa en el padre
                this.$emit('editar', this.modelo)
                // muestra mensaje
                this.$store.dispatch('show_snackbar', {
                  text: res.message,
                  color: 'success',
                })
              })
              .catch(error => {
                this.$store.dispatch('show_snackbar', {
                  text: error.message,
                  color: 'error',
                })
              })
          } else {
            this.dialog = false
            this.$store.dispatch('show_snackbar', {
              text: 'No se realizaron cambios en el modelo: el nombre y la marca son iguales a los anteriores',
              color: 'warning',
              timeout: 4000
            })
          }
        }
        this.load = false
      }
    },
    nueva_marca (marca) {
      // añade la nueva marca al array de marcas y la selecciona
      this.marcas.push(marca)
      this.modelo.marca_codigo = marca.codigo
    }
  }
}
</script>